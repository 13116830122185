import { Identifiable } from '@data/identifiable';
import { Location } from '@data/job/job.model';
import { ContextMode } from '@core/enums/app/context-mode';

export interface Session extends Identifiable {
  pin: string;
  name: string;
  creator: string;
  createdAt: Date;
  closedAt: Date;
  status: SessionStatus;
  users: string[];
  location?: Location;
  date?: Date;
  mode: ContextMode;
}
export enum SessionStatus {
  NEW,
  ACTIVE,
  CLOSED,
}
