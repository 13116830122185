import { Component, OnInit } from '@angular/core';
import { FilterableTableComponent } from '@shared/components/filterable-table/filterable-table.component';
import { EmptyConfig } from '@shared/components/placeholder/empty-page/empty-page.component';
import { Sort } from '@angular/material/sort';
import { compare } from '@shared/util/array.util';
import { PopulatedFairBooth } from '@data/fair-booth/fair-booth.model';
import moment from 'moment';

@Component({
  selector: 'recrewt-fairs-table',
  templateUrl: './fairs-table.component.html',
  styleUrls: ['./fairs-table.component.scss'],
})
export class FairsTableComponent extends FilterableTableComponent implements OnInit {
  emptyConfig?: EmptyConfig;

  ngOnInit(): void {
    super.ngOnInit();
    this.emptyConfig = {
      buttonIcon: 'add',
      buttonText: 'FAIRS.join',
      icon: 'confirmation_number',
      text: this.emptyMessage,
      headline: this.emptyTitle,
    };
  }

  sortBy = (sort: Sort, data: PopulatedFairBooth[]) => {
    const isAsc = sort.direction === 'asc';
    return data.sort((a, b) => {
      switch (sort.active) {
        case 'status':
          return compare(a.session.status, b.session.status, isAsc);
        case 'booth-nr':
          return compare(a.boothNr, b.boothNr, isAsc);
        case 'name':
          return compare(a.session.name, b.session.name, isAsc);
        case 'address':
          return compare(a.session.location?.address, b.session.location?.address, isAsc);
        case 'date':
          return compare(moment(a.session.date).unix(), moment(b.session.date).unix(), isAsc);
        default:
          return 0;
      }
    });
  };
}
