import { SpaceIonosService } from '@core/services/http/upload/space-ionos.service';
import { ResourceQuery } from '@data/resource/resource.query';
import { Resource } from '@data/resource/resource.model';
import { ResourceService } from '@data/resource/resource.service';
import { JobService } from '@data/job/job.service';
import { createTemplateFromDraft, Job, JobCreatorFinishedPayload, JobDraft } from '@data/job/job.model';
import { JobCreatorWizardService } from '../../../modules/job-creator/core/job-creator-wizard.service';
import { ContextMode } from '@core/enums/app/context-mode';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { AddJobToFairsDialogComponent } from '../../../modules/job-creator/dialog/add-job-to-fairs-dialog/add-job-to-fairs-dialog.component';
import { SpaceQuery } from '@data/space/space.query';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { first, Observable, of } from 'rxjs';
import { AddJobToBranchesDialogComponent } from '../../../modules/job-creator/dialog/add-job-to-branches-dialog/add-job-to-branches-dialog.component';
import { Space } from '@data/space/space.model';
import { Company } from '@data/company/company.model';
import { AddJobForStartYearsDialogComponent } from '../../../modules/job-creator/dialog/add-job-for-start-years-dialog/add-job-for-start-years-dialog.component';
import { cloneDeep } from 'lodash';

type Result = { data: JobCreatorFinishedPayload; job: Job; branches?: [Space, Company][]; startYears?: number[] };
export abstract class ResourceHandler {
  constructor(
    protected ionos: SpaceIonosService,
    private spaceQuery: SpaceQuery,
    protected resourceQuery: ResourceQuery,
    protected resourceService: ResourceService,
    protected jobService: JobService,
    protected jobCreator: JobCreatorWizardService,
    protected dialog: DialogService<any, any>,
  ) {}

  abstract onClicked(resource: Resource, args?: any): void;

  delete(resource: Resource): void {
    if (!resource.jobRef) return;
    const jobId = ResourceQuery.getJobIdFromResource(resource);
    this.deleteBanner(jobId);
  }

  openJobCreator(draft?: Partial<JobDraft>) {
    this.jobCreator.open(draft);
    this.waitForJobCreationCompletion();
  }

  protected createJob(data: JobCreatorFinishedPayload) {
    this.jobService
      .create(data.job)
      .pipe(
        filter((job) => !!job?.id),
        tap((job) => this.saveMediaAndTemplateForJob(data, job!)),
        switchMap((job) => this.askAddJobToFairs(job!, data)),
        switchMap((job) => this.askAddJobForStartYears({ job, data })),
        switchMap((result) => this.askAddJobToBranches(result)),
        first(),
      )
      .subscribe((result) => this.createAdditionalJobs(result));
  }

  private saveMediaAndTemplateForJob(data: JobCreatorFinishedPayload, job: Job) {
    this.saveBanner(data.banner, job!.id);
    this.saveMedia(data.media, job!.id);
    if (data.saveTpl && !data.skipCreateAdditional) {
      this.createTemplate(job!, data.banner);
    }
  }

  private askAddJobToFairs(job: Job, data: JobCreatorFinishedPayload) {
    if (job!.mode !== ContextMode.FAIR) {
      return of(job);
    }
    return this.addJobToFairsDialog(job, data);
  }

  private addJobToFairsDialog(job: Job, data: JobCreatorFinishedPayload) {
    this.dialog.open(AddJobToFairsDialogComponent, { job: job, preselect: [data.fairId] }, { width: '80vw' });
    return this.dialog.confirmed().pipe(map(() => job));
  }

  private askAddJobToBranches(result: Result) {
    if (result.job.mode !== ContextMode.FAIR || result.data.skipCreateAdditional) {
      return of(result).pipe(first());
    }

    const hasOrIsBranch$ = this.spaceQuery.hasOrIsBranch$;
    return hasOrIsBranch$.pipe(
      first(),
      switchMap((hasOrIsBranch) => {
        if (!hasOrIsBranch) {
          return of(result).pipe(first());
        }
        return this.addJobToBranchesDialog(result.job).pipe(
          tap((branches) => (result.branches = branches)),
          map(() => result),
        );
      }),
    );
  }

  private askAddJobForStartYears(result: Result) {
    if (result.job.mode === ContextMode.FAIR || result.data.skipCreateAdditional) {
      return of(result).pipe(first());
    }
    return this.addJobForStartYearsDialog(result.job).pipe(
      tap((years) => (result.startYears = years)),
      map(() => result),
    );
  }

  private addJobToBranchesDialog(job: Job) {
    this.dialog.open(AddJobToBranchesDialogComponent, { job }, { width: '80vw' });
    return this.dialog.confirmed() as Observable<[Space, Company][]>;
  }

  private addJobForStartYearsDialog(job: Job) {
    this.dialog.open(AddJobForStartYearsDialogComponent, job);
    return this.dialog.confirmed() as Observable<number[]>;
  }

  private createJobForBranch(branch: Space, company: Company, data: JobCreatorFinishedPayload) {
    const job: JobDraft = {
      ...data.job,
      siteId: company.sites![0].id,
      location: company.sites![0].location,
      company: branch.companyRef,
    };
    return { ...data, job, skipCreateAdditional: true } as JobCreatorFinishedPayload;
  }

  private createAdditionalJobs(result: Result) {
    result.data.skipCreateAdditional = true;
    this.createJobsForStartYears(result.startYears ?? [], result.data);

    result.branches?.forEach(([branch, company]) => {
      this.createJob(this.createJobForBranch(branch, company, result.data));
      this.createJobsForStartYears(result.startYears ?? [], this.createJobForBranch(branch, company, result.data));
    });
  }

  private createJobsForStartYears(years: number[], data: JobCreatorFinishedPayload) {
    years?.forEach((year) => {
      const dataClone = cloneDeep(data);
      const yearDiff = year - data.job.startDate?.getFullYear()!;
      dataClone.job.startDate?.setFullYear(year);
      dataClone.job.endDate?.setFullYear(dataClone.job.endDate!.getFullYear()! + yearDiff);
      dataClone.job.deadline?.setFullYear(dataClone.job.deadline!.getFullYear()! + yearDiff);
      this.createJob(dataClone);
    });
  }

  private waitForJobCreationCompletion() {
    this.jobCreator.confirmed().subscribe((data) => {
      this.handleJobCreationCompletion(data);
    });
  }

  private handleJobCreationCompletion(data: JobCreatorFinishedPayload) {
    if (!data?.job) return;
    this.deleteBanner(data.job.id);
    this.createJob(data);
  }

  private saveBanner(banner: File | null, jobId?: string) {
    if (!banner || !jobId) return;
    this.ionos.uploadDisplayImage('jobs', 'job/banner', jobId, banner);
  }

  private saveMedia(media: File[] | null, jobId?: string) {
    if (!media?.length || !jobId) return;
    for (const file of media) {
      this.ionos.upload('jobs', 'job/media', jobId, file);
    }
  }

  private deleteBanner(jobId?: string) {
    if (!jobId) return;
    this.ionos.removeDisplayImage('jobs', jobId, 'job/banner', 'banner');
  }

  private createTemplate(job: Job, banner: File | null): void {
    this.jobService.create(createTemplateFromDraft(job)).subscribe((tpl) => {
      this.saveBanner(banner, tpl?.id);
    });
  }
}
