import { Component, OnInit } from '@angular/core';
import { SpaceQuery } from '@data/space/space.query';
import { BRANCH_TAG, CURRENT_TAG, PARENT_TAG } from '@core/constants/space/tags';
import { Space } from '@data/space/space.model';
import { SpaceService } from '@data/space/space.service';
import { Router } from '@angular/router';
import { appRouteNames } from '../../../../app.routing.names';
import { map } from 'rxjs/operators';

@Component({
  selector: 'recrewt-space-switch-button',
  templateUrl: './space-switch-button.component.html',
  styleUrls: ['./space-switch-button.component.scss'],
})
export class SpaceSwitchButtonComponent implements OnInit {
  activeSpace$ = this.spaceQuery.selectActive();

  spaces$ = this.spaceQuery.parentAndBranches$.pipe(
    map((spaces) => spaces.filter((space) => space.id !== this.spaceQuery.getActiveId())),
  );

  readonly PARENT_TAG = PARENT_TAG;

  readonly BRANCH_TAG = BRANCH_TAG;

  readonly CURRENT_TAG = CURRENT_TAG;

  protected readonly length = length;

  constructor(private spaceQuery: SpaceQuery, private spaceService: SpaceService, private router: Router) {}

  ngOnInit(): void {}

  openSpace(space: Space): void {
    this.spaceService.deactivate();
    this.router.navigate([appRouteNames.SPACE.replace(':id', space.id)]);
  }

  toOverview(): void {
    this.router.navigate([appRouteNames.HOME]);
  }
}
