import { Injectable } from '@angular/core';
import { Resource, ResourceType } from '@data/resource/resource.model';
import { ResourceQuery } from '@data/resource/resource.query';
import { ResourceService } from '@data/resource/resource.service';
import { JobService } from '@data/job/job.service';
import { ResourceStore } from '@data/resource/resource.store';
import { JobCreatorWizardService } from '../../../modules/job-creator/core/job-creator-wizard.service';
import { createTemplateFromDraft, Job, JobDraft } from '@data/job/job.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SpaceIonosService } from '@core/services/http/upload/space-ionos.service';
import { SpaceRouter } from '@core/services/space-router/space-router.service';
import { JobStore } from '@data/job/job.store';
import { ResourceHandler } from '@core/services/resource/resource.handler';
import { JobHandler } from '@core/services/resource/job.handler';
import { TemplateHandler } from '@core/services/resource/template.handler';
import { DraftHandler } from '@core/services/resource/draft.handler';
import { isEmpty } from 'lodash';
import { LocalJobDatabase } from '@data/job/local-job-database.service';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { SpaceQuery } from '@data/space/space.query';

@Injectable({
  providedIn: 'root',
})
export class ResourceInteractionHandler {
  constructor(
    protected resourceStore: ResourceStore,
    private ionos: SpaceIonosService,
    private spaceQuery: SpaceQuery,
    protected resourceQuery: ResourceQuery,
    protected resourceService: ResourceService,
    protected jobService: JobService,
    protected jobStore: JobStore,
    protected localJobDb: LocalJobDatabase,
    protected jobCreator: JobCreatorWizardService,
    protected router: SpaceRouter,
    protected dialog: DialogService<any, any>,
  ) {}

  get(): void {
    this.resourceService.get();
  }

  for(resourceType: ResourceType): ResourceHandler | undefined {
    switch (resourceType) {
      case 'job':
        return this.createJobHandler();
      case 'draft':
        return this.createDraftHandler();
      case 'template':
        return this.createTemplateHandler();
    }
  }

  delete(resource: Resource): void {
    this.for(resource.type)?.delete(resource);
  }

  onClicked(router: Router, relativeTo: ActivatedRoute | null, event: { resource: Resource; params: any }): void {
    const resource = event.resource;
    this.for(resource.type)?.onClicked(resource);
  }

  update(value: { resource: Resource; changes: Partial<Resource> }): void {
    if (isEmpty(value.changes)) return;
    this.resourceStore.update(value.resource.id, value.changes);
    this.resourceService.update(value.resource.id, value.changes)?.subscribe(
      () => {},
      () => {
        const oldState = value.resource;
        this.resourceStore.update(oldState.id, oldState);
      },
    );
  }

  duplicate(resource: Resource): void {
    const data = this.resourceQuery.getJobFromResource(resource);
    this.createJob(data);
  }

  togglePinnedState(value: { resource: Resource; attach: boolean }): void {
    this.localJobDb.togglePinnedState(value.resource, value.attach);
  }

  createJob(draft?: Partial<JobDraft>): void {
    this.for('job')?.openJobCreator(draft);
  }

  createTemplate(job: Job, banner: File | null): void {
    this.jobService.create(createTemplateFromDraft(job)).subscribe((tpl) => {
      if (!!tpl?.id && !!banner) {
        this.ionos.uploadDisplayImage('jobs', 'job/banner', tpl?.id, banner)?.subscribe();
      }
    });
  }

  private createJobHandler() {
    return new JobHandler(
      this.ionos,
      this.spaceQuery,
      this.resourceQuery,
      this.resourceService,
      this.jobService,
      this.jobCreator,
      this.dialog,
      this.router,
    );
  }

  private createDraftHandler() {
    return new DraftHandler(
      this.ionos,
      this.spaceQuery,
      this.resourceQuery,
      this.resourceService,
      this.jobService,
      this.jobCreator,
      this.dialog,
    );
  }

  private createTemplateHandler() {
    return new TemplateHandler(
      this.ionos,
      this.spaceQuery,
      this.resourceQuery,
      this.resourceService,
      this.jobService,
      this.jobCreator,
      this.dialog,
      this.jobStore,
    );
  }
}
