<div [class.hover-effect]="!matStyle && !disabled">
  <button
    #origin
    (click)="open(dropdown, origin); $event.stopPropagation()"
    (focus)="open(dropdown, origin)"
    [disabled]="disabled"
    class="mat-body-1 no-pad select-trigger"
    fxFlex="grow"
    type="button"
  >
    <ng-template [ngIfElse]="searchTpl" [ngIf]="!isOpen">
      <ng-template [ngIf]="!originTpl">
        <ng-template [ngIfElse]="defaultOrigin" [ngIf]="matStyle">
          <mat-form-field [formGroup]="selectFormGroup" appearance="outline" fxFlex="grow">
            <mat-label *ngIf="placeholder">{{ placeholder | translate }}</mat-label>
            <mat-error>{{ error | translate }}</mat-error>
            <input
              [disabled]="disabled"
              [formControlName]="selectControlName"
              [required]="require"
              [value]="label | translate"
              autocomplete="off"
              class="mat-body-1"
              matInput
            />
          </mat-form-field>
        </ng-template>
        <ng-template #defaultOrigin>
          <p *ngIf="placeholder && !label" class="on-surface-medium no-pad p-1">{{ placeholder | translate }}</p>
          <p *ngIf="label" [class.on-surface-medium]="disabled" class="no-pad p-1">
            {{ label | translate }}
          </p>
        </ng-template>
      </ng-template>
      <ng-template *ngTemplateOutlet="originTpl"></ng-template>
    </ng-template>
    <ng-template #searchTpl>
      <ng-template [ngIfElse]="defaultSearch" [ngIf]="matStyle">
        <mat-form-field appearance="outline" fxFlex="grow">
          <mat-label *ngIf="placeholder">{{ placeholder | translate }}</mat-label>
          <input
            (click)="$event.stopPropagation()"
            [disabled]="disabled"
            [formControl]="searchControl"
            [required]="require"
            autocomplete="off"
            autofocus
            class="mat-body-1"
            matInput
            takeFocus
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </ng-template>
      <ng-template #defaultSearch>
        <input
          (click)="$event.stopPropagation()"
          [disabled]="disabled"
          [formControl]="searchControl"
          [placeholder]="placeholder | translate"
          [style.height.px]="optionHeight"
          class="mat-styled-input mat-body-1"
          fxFlex="grow"
          id="search"
          takeFocus
          type="text"
        />
      </ng-template>
    </ng-template>
  </button>

  <ng-template #dropdown>
    <div class="select-menu mat-elevation-z3">
      <ng-container *ngIf="(loading$ | async) === false || (loading$ | async) == null; else itemsLoading">
        <cdk-virtual-scroll-viewport
          [itemSize]="optionHeight"
          [style.height.px]="visibleOptions * optionHeight"
          [style.max-height.px]="visibleOptions * optionHeight"
          class="select-menu-inner"
        >
          <div *ngIf="!options.length" [style.height.px]="optionHeight" class="no-results-item on-surface-medium">
            {{ 'PLACEHOLDER.no_results' | translate }}
          </div>
          <div
            (click)="select(option)"
            *cdkVirtualFor="let option of options; index as i"
            [class.active]="isActive(option)"
            [class.on-surface-medium]="isOptionDisabled(option)"
            [matRippleDisabled]="isOptionDisabled(option)"
            [style.height.px]="optionHeight"
            class="select-item"
            matRipple
          >
            <span>
              <ng-template [ngIf]="!optionTpl">{{ displayValue(option) | translate }}</ng-template>
              <ng-template *ngTemplateOutlet="optionTpl; context: { $implicit: option, idx: i }"></ng-template>
              <span *ngIf="isOptionDisabled(option) && disabledOptionSuffix" class="p-l-s"
                >- {{ disabledOptionSuffix | translate }}</span
              >
            </span>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </div>
  </ng-template>
</div>

<ng-template #itemsLoading>
  <div style="height: 204px; padding-top: 4px">
    <ngx-skeleton-loader
      *ngFor="let i of [0, 1, 2, 3]"
      [theme]="{ width: '90%', height: '20px', margin: '12px 5%', padding: '0', backgroundColor: '#ebebeb' }"
    >
    </ngx-skeleton-loader>
  </div>
</ng-template>
