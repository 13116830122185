// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import auth_config from '../../auth_config-dev.json';

export const environment = {
  production: false,
  firebase: {
    webApiKey: 'AIzaSyDw8wppmktEyM7OFjoXfCrGRjHquyK_vqA',
  },
  auth: {
    domain: auth_config.domain,
    clientId: auth_config.clientId,
    redirectUri: window.location.origin + '/home/spaces',
    audience: auth_config.audience,
  },
  apiUrl: '/api',
  // apiUrl: 'http://localhost:3000/api',
  mapbox: {
    apiKey: 'sk.eyJ1IjoidmF2ZS1kZXYiLCJhIjoiY2t1YzgwdDN4MHh2ZTJ3bG1nam03NGJxbSJ9.Yc3o-RsHZSgpS_cbzRCwxA',
  },
  stripe: {
    publicKey:
      'pk_test_51K2HDCKRNZejXORbRhBEg8SLOFqMGZY29rsSswOf7a1cgq2GaX8ciPziBbryOQUgFSldC2heSYsNLfVlIwSukaOK00GKvTEJGi',
  },
  rollbar: {
    key: '732b439fd0114f10a8be73401fed5958',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
