<div class="p-h-2 p-b-1">
  <div [formGroup]="form" fxLayout="column">
    <p>{{ 'SPACES.CREATE.change_msg' | translate }}</p>
    <mat-form-field appearance="outline">
      <input formControlName="name" matInput required />
      <mat-error>{{ 'COMMON.required' | translate }}</mat-error>
      <mat-label>{{ (data.parentRef ? 'SPACES.CREATE.branch_name' : 'SPACES.CREATE.c_name') | translate }}</mat-label>
    </mat-form-field>
    <div *ngIf="!data.edit && !data.parentRef">
      <h3>{{ 'COMPANY_PROFILE.GENERAL.sites' | translate }}</h3>
      <recrewt-sites-list [editable]="isAddSiteAllowed$ | async" formControlName="sites"></recrewt-sites-list>
    </div>
    <div *ngIf="!data.edit && !!data.parentRef">
      <h3>Standort*</h3>
      <recrewt-location-selector
        [require]="!data.edit && !!data.parentRef"
        controlName="location"
      ></recrewt-location-selector>
    </div>
    <div *ngIf="data.parentRef && !data.edit" [formGroup]="form" class="m-b-2 m-t-1">
      <mat-checkbox formControlName="copyProfile">{{ 'SPACES.CREATE.copy_profile' | translate }}</mat-checkbox>
    </div>
    <div *ngIf="!data.parentRef && !data.edit" [formGroup]="form" class="m-b-2 m-t-2" fxLayout="column">
      <mat-checkbox formControlName="branched">{{ 'SPACES.CREATE.branched' | translate }}</mat-checkbox>
      <mat-hint>{{ 'SPACES.CREATE.branched_hint' | translate }}</mat-hint>
    </div>
    <div class="m-v-2 width-match-parent">
      <h3>{{ 'SPACES.CREATE.invite' | translate }}</h3>
      <ng-container *ngIf="!data.parentRef; else branchHintTpl">
        <p>{{ 'SPACES.CREATE.invite_msg' | translate }}</p>
        <table
          *ngIf="(loading$ | async) === false; else tableLoadingTpl"
          [dataSource]="dataSource"
          class="m-t-2 width-match-parent"
          mat-table
        >
          <ng-container matColumnDef="email">
            <th *matHeaderCellDef mat-header-cell>E-Mail</th>
            <td *matCellDef="let data" mat-cell style="width: 400px">
              <recrewt-text-field-editor
                #input
                (update)="addRow($event); clearInput(input)"
                *ngIf="data.footer"
                [placeholder]="'SPACES.CREATE.add_collab' | translate"
                type="email"
              ></recrewt-text-field-editor>
              <p *ngIf="!data.footer" class="no-pad">{{ data.email }}</p>
            </td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th *matHeaderCellDef mat-header-cell>{{ 'SPACES.CREATE.role' | translate }}</th>
            <td *matCellDef="let collaborator" mat-cell style="width: 200px">
              <recrewt-select
                (selectChange)="collaborator.role = $event; updateHasAdmin(); form.markAsDirty()"
                *ngIf="!collaborator.footer"
                [disabled]="!collaborator.email || collaborator.email === data.email"
                [model]="collaborator.role"
                [options]="OPTIONS"
                class="height-match-parent"
                labelKey="name"
                placeholder="SPACES.CREATE.role"
                valueKey="value"
              ></recrewt-select>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell>
              <div fxLayoutAlign="center center"></div>
            </th>
            <td *matCellDef="let collaborator" mat-cell>
              <recrewt-collaborator-actions
                (deleteClicked)="deleteRow(collaborator.email)"
                (resendClicked)="resendInvite(collaborator.email)"
                *ngIf="collaborator !== FOOTER_DATA"
                [disableDelete]="!collaborator.email || collaborator.email === data.email"
                [disableResend]="!collaborator.resend"
              ></recrewt-collaborator-actions>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
        </table>
      </ng-container>
    </div>
    <div *ngIf="!atLeastOneAdmin" class="err-box m-v-2" fxFlex="grow" fxLayoutAlign="center">
      <p class="no-pad">{{ 'SPACES.CREATE.ERROR.at_least_one_admin' | translate }}</p>
    </div>
  </div>
</div>

<ng-template #tableLoadingTpl>
  <div class="width-match-parent height-match-parent m-v-2" fxLayout="row" fxLayoutAlign="center center">
    <mat-progress-spinner color="primary" diameter="40" mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
<ng-template #branchHintTpl>
  <div class="width-match-parent height-match-parent m-v-2" fxLayout="row" fxLayoutAlign="center center">
    <p class="no-pad mat-hint">{{ 'SPACES.CREATE.HINT.no_branch_collab_edit' | translate }}</p>
  </div>
</ng-template>
